:root {
  --body-background: #23314b;
  --body-color: #23314b;
  --heading-color: 23314b;
  --action-color: #DF7785;
  --header-color: #c35866;
  --light-action-color: #f1c1c7;
  --gray: rgb(161, 161, 161);
  --yellow: #efb96e;
  --light-yellow: #fdf2d7;
}

body {
  background: var(--body-color);
  background-image: url("../../src/images/AmeliaNavy.jpg");
  background-size: 85rem;
  background-position-y: -5rem;
  color: var(--body-color);
  font-family: "Source Sans Pro", BlinkMacSystemFont, -apple-system, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    "Helvetica", "Arial", sans-serif;
  margin: 0 8px;
  font-size: 108%;
  line-height: 1.75;
}

@media (max-width: 500px) {
  body {
    background-size: 30rem;
  }
}

a {
  color: var(--action-color);
  text-decoration: underline;
  text-decoration-color: var(--yellow);
}

a.disabled {
  color: var(--gray);
  pointer-events: none;
  text-decoration: none;
}

h1 {
  font-family: "Josefin Sans", "sans-serif";
  margin: 1rem 0 1rem;
  font-weight: 900;
  font-size: 2.5rem;
  color: var(--heading-color);
  line-height: 1.2;
}

body > header {
  margin: 5rem auto 0 auto;
  padding: 3rem 3rem 2rem 3rem;
  text-align: center;
  background: var(--header-color);
  color: white;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 40px;
  max-width: 50rem;
  border-radius: 3px 3px 0 0;
}

body > header > nav ul {
  margin: 1rem 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  font-size: 1.3rem;
  font-weight: bold;
  flex-wrap: wrap;
}

body > header > nav a {
  text-decoration: none;
  color: var(--light-action-color);
}

body > header > nav a:hover {
  color: white;
}

body > header .title {
  font-family: "Josefin Sans", "sans-serif";
}

main {
  margin: 0 auto;
  max-width: 50rem;
  padding: 3rem;
  background: white;

  @media (max-width: 500px) {
    padding: 16px 16px 50px;
  }
}

main p.subtitle {
  color: var(--gray);
  font-size: 14px;
  margin: 0;
  margin-top: -1rem;
}

main > h3 {
  margin-top: 3rem;
}

footer {
  text-align: center;
  margin: 0 auto 4rem auto;
  font-size: 1em;
  background: var(--header-color);
  border-radius: 0 0 3px 3px;
  padding: 1rem 3rem;
  max-width: 50rem;
  color: white;
  text-transform: uppercase;
  font-weight: 600;
}

footer > a {
  color: var(--light-action-color);
  text-decoration: none;
}

footer > a:hover {
  color: white;
  text-decoration: none;
}

hr {
  border: none;
  border-top: 2px dotted #bbb;
  margin: 3rem 0;
}

main > p.post-date {
  margin-bottom: 2rem;
  color: var(--gray);
}

main > p.note {
  background: var(--light-yellow);
  padding: 1rem;
  border-radius: 3px;
}

main > .pagination {
  margin-top: 2rem;
  text-align: center;
}

main pre.highlight {
  overflow-x: auto;
}
